import React from 'react';
import {
  ComponentPreview,
  ComponentWithLabel,
  DontDo,
  DontDoItem,
  List,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformExceptionList,
  PlatformExceptionItem,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Button, Icon } from 'hudl-uniform-ui-components';
import buttonPreviewData from '../../../../data/previews/button.yml';
import pageHeroData from '../../../../data/pages/components.yml';
import './styles/button.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Button"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Buttons" tierThree="Button" />

      <PageNavigation>
        <PageNavigationLink>Type</PageNavigationLink>
        <PageNavigationLink>Style</PageNavigationLink>
        <PageNavigationLink>Size</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>State</PageNavigationLink>
        <PageNavigationLink>Microcopy</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Type">
        <Paragraph>
          The button type is determined by its purpose and the action being
          taken.
        </Paragraph>
        <ComponentPreview
          name="ButtonType"
          layout="split"
          previewData={buttonPreviewData.types}>
          <Button text="Standard" />
          <Button text="Minimal" style="minimal" />
        </ComponentPreview>

        <SectionSubhead>Icons</SectionSubhead>
        <Paragraph>
          A button can be all text, a single icon or both. For icon-only, make
          sure the action is clearly implied.
        </Paragraph>
        <ComponentPreview name="ButtonIcon" layout="split">
          <ComponentWithLabel labelText="Text">
            <Button type="primary" text="Exchange" />
          </ComponentWithLabel>
          <ComponentWithLabel labelText="Icon & Text">
            <Button
              type="primary"
              text="Exchange"
              icon={<Icon type="exchanges" />}
            />
          </ComponentWithLabel>
          <ComponentWithLabel labelText="Icon Only">
            <Button type="primary" icon={<Icon type="exchanges" />} />
          </ComponentWithLabel>
        </ComponentPreview>
      </Section>

      <Section title="Style">
        <Paragraph>
          Your button style depends on how much attention you want to draw to
          the action.
        </Paragraph>
        <ComponentPreview
          name="ButtonStyle"
          layout="row"
          previewData={buttonPreviewData.styles}>
          <Button type="primary" text="Primary" />
          <Button type="secondary" text="Secondary" />
          <Button type="subtle" text="Subtle" />
          <Button type="destroy" text="Destroy" />
          <Button type="confirm" text="Confirm" />
        </ComponentPreview>
      </Section>

      <Section title="Size">
        <Paragraph>
          Consider view density and the button’s position when choosing between
          the four available sizes.
        </Paragraph>
        <ComponentPreview
          name="ButtonSize"
          layout="split"
          previewData={buttonPreviewData.sizes}>
          <Button type="primary" text="Standard" />
          <Button type="primary" text="Minimal" style="minimal" />
        </ComponentPreview>

        <SectionSubhead>Associated Actions</SectionSubhead>
        <Paragraph>
          Multiple sizes should never be placed adjacent to one another.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use multiple sizes in the same context."
            img="button-associatedactions-dont"
          />
          <DontDoItem
            type="do"
            text="use button types to emphasize a preferred option."
            img="button-associatedactions-do"
          />
        </DontDo>
      </Section>
      <Section title="Mobile">
        <Paragraph>
          Touchpoint sizes on mobile devices differ than the button target area
          on the web. To make Uniform accessible on both platforms, the x-small
          button is unavailable for mobile.
        </Paragraph>
        <PlatformExceptionList>
          <PlatformExceptionItem platform="apple">
            Use medium as the default for most interfaces, but small works just
            fine, too. Reserve large for iPads.
          </PlatformExceptionItem>
          <PlatformExceptionItem platform="android">
            {' '}
            Medium is ideal for most interfaces, but large also works. Use small
            sparingly.
          </PlatformExceptionItem>
        </PlatformExceptionList>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Primary Actions</SectionSubhead>
        <Paragraph>
          Because our Action color demands attention, limit the number of
          primary actions per page. (We strongly recommend just one.)
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use many primary actions on a single view."
            img="button-limitprimaryactions-dont"
          />
          <DontDoItem
            type="do"
            text="use secondary and subtle buttons to create hierarchy without overwhelming the screen."
            img="button-limitprimaryactions-do"
          />
        </DontDo>

        <SectionSubhead>Statuses</SectionSubhead>
        <Paragraph>
          Not every button needs the following statuses. Save them for actions
          that could take some time to complete.
        </Paragraph>
        <Paragraph>
          Statuses come as a package deal. Never use one on its own.
        </Paragraph>
        <ComponentPreview
          name="ButtonStatus"
          layout="row"
          previewData={buttonPreviewData.statuses}>
          <Button type="secondary" text="Saving..." />
        </ComponentPreview>

        <SectionSubhead>Mobile</SectionSubhead>
        <Paragraph>
          When using buttons for mobile, consider placement while keeping in
          mind what HIG and Material have to say. We want Uniform to fit in
          nicely with other system components and patterns, like{' '}
          <Link href="/patterns/mobile-navigation">mobile navigation</Link>.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use standard buttons in navigation."
            img="button-mobile-standard-nav-dont"
          />
          <DontDoItem
            type="do"
            text="choose buttons to fit seamlessly within the OS."
            img="button-mobile-standard-nav-do"
          />
        </DontDo>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="randomly change the button elevation."
            img="button-mobile-elevation-dont"
          />
          <DontDoItem
            type="do"
            text="follow elevation guidelines if they exist."
            img="button-mobile-elevation-do"
          />
        </DontDo>
      </Section>

      <Section title="State">
        <Paragraph>
          The button’s state changes with mouse and keyboard interactions. We
          can also disable a button entirely.
        </Paragraph>
        <ComponentPreview name="ButtonType" layout="split">
          <ComponentWithLabel labelText="Default" showStates>
            <Button type="primary" text="Upload Video" />
          </ComponentWithLabel>

          <ComponentWithLabel labelText="Disabled">
            <Button type="primary" text="Upload Video" disabled />
          </ComponentWithLabel>
        </ComponentPreview>
      </Section>

      <Section title="Microcopy">
        <SectionSubhead>Case & Punctuation</SectionSubhead>
        <Paragraph>
          Buttons follow the{' '}
          <Link href="/words/content-elements/labels-non-label-text">
            UI label guidelines
          </Link>
          . Use <strong>title case without punctuation</strong> unless noted
          otherwise.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="label buttons with full sentences."
            img="button-caseandpunctuation-dont"
          />
          <DontDoItem
            type="do"
            text="make buttons title case without punctuation."
            img="button-caseandpunctuation-do"
          />
        </DontDo>

        <SectionSubhead>Action</SectionSubhead>
        <Paragraph>
          Labels should clearly indicate the action being taken.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="make button labels so generic there's no clear outcome."
            img="button-action-dont"
          />
          <DontDoItem
            type="do"
            text="label with specific, action-oriented verbs."
            img="button-action-do"
          />
        </DontDo>

        <SectionSubhead>Hudl Voice</SectionSubhead>
        <Paragraph>
          Button labels are a great opportunity to inject personality with the{' '}
          <Link href="/words/writing-guidelines/voice-tone">Hudl voice</Link>.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="make labels distracting with excessively playful language."
            img="button-hudlvoice-dont"
          />
          <DontDoItem
            type="do"
            text="write labels that sound human."
            img="button-hudlvoice-do"
          />
        </DontDo>

        <SectionSubhead>Length</SectionSubhead>
        <Paragraph>
          Button labels should be <strong> between 5 and 25 characters</strong>.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="make button labels long and difficult to read."
            img="button-length-dont"
          />
          <DontDoItem
            type="do"
            text="make button labels concise. Use supporting text for additional context if needed."
            img="button-length-do"
          />
        </DontDo>
      </Section>

      <Section title="Platform">
        <PlatformTable>
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple" />
          <PlatformTableRow platform="android" />
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
